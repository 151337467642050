<template>
    <div class="content-body">
        <div class="header">
            <div class="practice-class">
                <div class="question-class">
                    <div class="text">试题分类</div>
                    <el-cascader
                            v-model="questionsClassValue"
                            class="w350-cascader"
                            :options="questionsClassList"
                            :props="keyObjClass"
                            clearable
                            @change="handleChangeQuestionsClass">
                    </el-cascader>
                </div>
                <!-- <div class="module-class">
                    <div class="text">模块</div>
                    <el-cascader
                            v-model="moduleValue"
                            class="w350-cascader"
                            :options="moduleList"
                            :props="keyObjModule"
                            clearable
                            @change="handleChangeModule">
                    </el-cascader>
                </div> -->
            </div>
            <div class="search-add">
                <el-input v-model="searchPracticeValue"
                          placeholder="请输入内容"
                          slot="prepend"
                          class="select-list-right w300-input"
                          @keyup.enter.native="practiceSearch">
                    <el-button slot="append" icon="el-icon-search" @click="practiceSearch"></el-button>
                </el-input>
                <el-button type="primary" @click="addPractice" class="w94-green-btn">
                    <i class="iconfont">&#xe609;</i>
                    添加试题
                </el-button>
            </div>
        </div>
        <el-table :data="practiceQuestionsList" height="1%" size="medium"
                  class="content-table customTable"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
                  :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
            <el-table-column prop="op_name" align="center" label="名称"></el-table-column>
            <el-table-column prop="category" align="center" label="试题分类"></el-table-column>
            <!-- <el-table-column prop="exam_module" align="center" label="考试模块"></el-table-column> -->
            <el-table-column align="center" label="操作" width="200">
                <template slot-scope="scope">
                    <div class="link-box">
                        <el-link type="success" :underline="false" @click="editPractice(scope.row.id)">编辑</el-link>
                        <el-link type="danger" :underline="false" @click="delPractice(scope.row.id)">删除</el-link>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pager-center" style="margin: 20px 0;text-align: center"
                       :current-page="practicePages.currentPageNum"
                       :page-size="practicePages.eachPageNum"
                       :total="practicePages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="practiceCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import {getActualCateData, operationProblemExamModuleList, operationProblemOpList, operationProblemOpDetail, operationProblemOpDel} from '@/utils/apis'
    export default {
        name: "Index",
        data() {
            return {
                //实操题列表
                practiceQuestionsList: [],
                //试题分类列表
                questionsClassList: [],
                keyObjClass: {
                    label: 'name',
                    value: 'id',
                    children: 'children'
                },
                questionsClassValue: [],
                //模块
                moduleList: [],
                keyObjModule: {
                    label: 'name',
                    value: 'id',
                    children: 'children'
                },
                moduleValue: [],
                //搜索
                searchPracticeValue: '',
                //分页
                practicePages:{
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
            }
        },
        mounted() {
            this.getQuestionsClassList();
            this.getModuleList();
            this.getPracticeList();
        },
        methods: {
            //获取试题分类列表
            getQuestionsClassList() {
                let param = {
                    // type: '4',
                    paging: "0"
                }
                getActualCateData(param).then((res) => {
                    this.clearEmptyCate(res.data);
                    this.questionsClassList = res.data;
                }).catch((err) => {
                    console.log(err);
                });
            },
            clearEmptyCate(arr) {
                // arr.forEach(item => {
                //     item.children.length <= 0 ? this.$delete(item, 'children') : this.clearEmptyCate(item.children);
                // })
            },
            //获取模块列表
            getModuleList() {
                let param = {
                    type: '3'
                }
                operationProblemExamModuleList(param).then((res) => {
                    this.moduleList = res.data;
                }).catch((err) => {
                    console.log(err);
                });
            },
            //获取实操题列表
            getPracticeList() {
                let param = {
                    page: this.practicePages.currentPageNum,
                    limit: this.practicePages.eachPageNum,
                }
                if (this.moduleValue.length > 0) {
                    param.exam_module_id = this.moduleValue[this.moduleValue.length - 1];
                }
                if (this.questionsClassValue.length > 0) {
                    param.category_id = this.questionsClassValue[this.questionsClassValue.length - 1];
                }
                if (this.searchPracticeValue) {
                    param.name = this.searchPracticeValue;
                }
                operationProblemOpList(param).then((res) => {
                    this.practiceQuestionsList = res.data.list;
                    this.practicePages.total = res.data.total;
                }).catch((err) => {
                    console.log(err);
                })
            },
            //选择试题分类
            handleChangeQuestionsClass() {
                this.practicePages.currentPageNum = 1
                this.getPracticeList();
            },
            //选择模块
            handleChangeModule() {
                this.practicePages.currentPageNum = 1
                this.getPracticeList();
            },
            //搜索
            practiceSearch() {
                this.practicePages.currentPageNum = 1
                this.getPracticeList();
            },
            //添加试题
            addPractice() {
                this.$router.push({
                    path: '/practice/create'
                })
            },
            //编辑
            editPractice(id) {
                let param = {
                    id: id
                }
                operationProblemOpDetail(param).then((res) => {
                    this.$router.push({
                        path: '/practice/create',
                        query: {
                            id: id
                        }
                    })
                }).catch((err) => {
                    console.log(err);
                })
            },
            //删除
            delPractice(id) {
                this.$confirm(`是否删除该试题，删除将无法恢复`, '删除', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                    type: 'warning'
                }).then(() => {
                    let param = {
                        id: id
                    }
                    operationProblemOpDel(param).then((res) => {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                            duration: 1000,
                            onClose: () => {
                                this.getPracticeList();
                            }
                        })

                    }).catch((err) => {
                        console.log(err);
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            },
            //分页
            practiceCurrentChange(val){
                this.practicePages.currentPageNum = val;
                this.getPracticeList();
            }
        }
    }
</script>

<style scoped lang="scss">
    .content-body {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 0 20px;
        box-sizing: border-box;
        .header {
            display: flex;
            justify-content: space-between;
            .practice-class {
                display: flex;
                align-items: center;
                .question-class, .module-class {
                    display: flex;
                    align-items: center;
                    margin-right: 30px;
                    .text {
                        font-size: 16px;
                        color: #333333;;
                        margin-right: 20px;
                    }
                }
            }
            .search-add {
                display: flex;
                align-items: center;
                .select-list-right {
                    margin-right: 30px;
                }
                .w94-green-btn {
                    i {
                        font-size: 12px;
                        margin-right: 5px;
                    }
                }
            }
        }
        .content-table {
            width: 100%;
            margin-top: 20px;
            flex: 1;
        }
    }
</style>